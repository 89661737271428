// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
    color: $grey500;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    overflow: visible !important;
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}
@font-face {
    font-family: Inter-Bold;
    src: url('../fonts/Inter-Bold.ttf') format('truetype');
    font-weight: 700 800 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Inter-Medium;
    src: url('../fonts/Inter-Medium.ttf') format('truetype');
    font-weight: 500 medium;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: Inter-SemiBold;
    src: url('../fonts/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600 semibold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: Inter;
    src: url('../fonts/Inter-Regular.ttf') format('truetype');
    font-weight: 400 normal;
    font-style: normal;
    font-display: swap;
}
.editable {
    font-family: Inter;
    font-size: 14px;
    color: #7a7a7a;
    height: 100%;
}
.ql-container.ql-snow {
    border: none !important;
}
.ql-toolbar.ql-snow {
    border: none;
    box-sizing: border-box;
    font-family: Inter;
    padding: 20px;
    background: #eaecf0;
    border-radius: 10px 10px 0 0;
}
.quill > .ql-container > .ql-editor.ql-blank::before {
    font-style: normal;
    font-size: 14px;
}
.ReactCrop {
    display: block !important;
}
.link {
    text-transform: capitalize;
    color: #aaa;
    font-family: Inter;
    font-weight: 500;
    text-decoration: none;
}
.textDecorationNone {
    text-decoration: none;
}
.MuiMenu-list {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 8;
    box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.1);
    border: 1px solid #d4dbea;
}
.spanFacebook {
    color: #393d4e;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    margin-right: 5px;
}
.colorLink {
    color: #677487;
}
.spanFacebookStep3 {
    color: #000;
    margin: 0px 5px;
    font-weight: 600;
}
.linkStep3 {
    color: #5a5a5a;
    font-style: italic;
}
.linkRelevant {
    color: #393d4e;
    display: flex;
    text-decoration: none;
}
.scroleNone::-webkit-scrollbar {
    width: 0;
}

body {
    zoom: 100%;
}
.react-flow__controls {
    bottom: 300px;
}

/* .react-flow__renderer {
    transform: translateX(25%);
}

@media (max-width: 1200px) {
    .react-flow__renderer {
        transform: translateX(20%);
    }
} */

.noneScrollBar::-webkit-scrollbar {
    width: 0;
}
.noneUnerline {
    text-decoration: none;
}
.downloadImage {
    position: absolute;
    top: 10px;
    right: 10px;
}
.react-flow__edge-path {
    stroke-width: 1.5;
    stroke: #98a2b3;
}

.react-flow__edge.selected .react-flow__edge-path {
    stroke-width: 2.5;
    stroke: #ee9a8f;
}
.delbutton {
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    background: #fff;
    transition-property: opacity, visibility;
    transition-duration: 0.5s;
}

.react-flow__edge.selected .delbutton {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    min-width: 10px;
}
.react-flow__node-trigger.selected .delbutton {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    min-width: 10px;
}
.react-flow__node-end.selected .delbutton {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    min-width: 10px;
}
.react-flow__edge-canvas {
    cursor: pointer;
}
.react-flow__controls {
    display: none;
}
.react-flow__handle {
    opacity: 0;
}
.react-flow__attribution {
    display: none;
}
.group-or-rule-container {
    padding-right: 0px;
}
