.edgebutton {
    width: 10px;
    height: 10px;
    background: grey;
    /* border: 1px solid #fff; */
    cursor: pointer;
    border-radius: 50%;
    font-size: 12px;
    line-height: 1;
}

.edgebutton:hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.edgebutton-foreignobject body {
    background: transparent;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
}
