.user-list__container {
    display: flex;
    flex-direction: column;
    // width: 95%; // added
    height: 100%;
    margin: 0 auto; // added
    // padding: 0 10px; // changed
    overflow-y: scroll; // added
    &_box {
        height: 100px;
        width: 100%;
        padding-top: 20px;
    }
    &_box1 {
        display: flex;
        align-items: center;
        width: auto;
        button {
            width: 36px;
            height: 36px;
            border: none;
            outline: none;
            background: #f6f7fc;
            border-radius: 5px;
            margin-right: 10px;
        }
    }
    h2 {
        display: flex;
        gap: 10px;
        margin: 0;
        padding: 30px 0;
        span {
            font-size: 14px; // added
            font-weight: 500; // added
        }
    }
    h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: var(--text-color);
    }
}

// .test_class {
//     font-size: 16px; // added
//     font-weight: 800; // added
// }

.user-list__message {
    margin: 20px;
}

.user-list__row.user-list__header {
    padding-block: 1rem;
}

.user-list__row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: 0.8rem;
    // padding-inline: 1rem;
    gap: 10px;
    &:first-child {
        margin-top: 20px;
    }

    p {
        margin: 0;
    }

    .user-list__column-block {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .user-list__column--user-data {
        flex: 1;
        display: flex;
        align-items: center;
        gap: 8px;
        white-space: nowrap;
        overflow: hidden;
    }

    .user-list__column--last-active {
        width: 40%;
    }

    .user-list__column--checkbox {
        flex: 1;
    }

    .user-list__column--last-active,
    .user-list__column--checkbox {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
