.str-chat-channel-list {
    height: fit-content;
    border-style: none;
}

.str-chat__load-more-button {
    display: none;
}

.team-channel-list {
    width: 100%;
    // margin:10px 0;

    .str-chat__avatar-fallback {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), var(--primary-color);
    }

    .team-channel-list__message {
        padding: 0 16px;
    }

    .team-channel-list__message.loading {
        height: 115px;
    }

    .team-channel-list__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;
        height: 20px;
        border-right-style: none;

        .team-channel-list__header__title {
            display: flex;
            align-items: center;
        }

        button {
            display: flex;
            align-items: center;
            padding-block: 4px;
        }
    }
}
