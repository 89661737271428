@use '../utils';

.channel-search__container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 25px;
    border-top: 1px solid var(--border-color);
    // border-bottom: 1px solid var(--border-color);
}

.channel-search__input__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    min-width: 100%;
    padding: 0 10px;

    input {
        min-width: 100%;
    }

    .channel-search__input__input {
        width: 32px;
        display: flex;
        justify-content: center;
    }
    .channel-search__input__icon {
        width: 60px;
        display: flex;
        justify-content: center;
        padding-left: 10px;
    }
}

.channel-search__results {
    position: absolute;
    // position: static;
    // height: fit-content;
    width: 100%;
    // width: 300px;
    z-index: 10;
    // z-index: 1000;
    // left: 230px;
    left: 0px;
    top: 45px;
    // top: 16px;

    .channel-search__results-header {
        width: fit-content;
        display: flex;
        align-items: center;
        margin-left: 12px;
        margin-top: 12px; // added
        margin-bottom: 12px; // added
        i {
            margin-left: 12px;
        }
    }

    .channel-search__result-container {
        display: flex;
        align-items: center;
        width: 100%;
        height: 48px;
        padding: 0 12px;
        gap: 8px;

        .result-hashtag {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 24px;
            width: 28px;
        }

        .channel-search__result-text {
            @include utils.ellipsis-text;
            width: 100%;
        }
    }
}
