@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .card-border {
        @apply border-[1.5px] border-solid border-grey-300 rounded-lg;
    }

    .card-shadow {
        @apply shadow-lg;
    }

    .button {
        @apply rounded-lg py-2 px-3 bg-primary-500 text-white border-2 border-solid border-primary-500 font-medium hover:bg-white hover:text-primary-500 transition-colors;
    }

    .button-secondary {
        @apply bg-white text-primary-500  hover:bg-primary-100;
    }

    .modal {
        @apply relative transform rounded-lg bg-white text-left shadow-xl transition-all;
    }

    .modal-sm {
        @apply sm:my-8 sm:w-full sm:max-w-lg;
    }

    .label {
        @apply font-medium text-main text-sm mb-2;
    }
}
