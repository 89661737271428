.str-chat {
    --str-chat__message-list-background-color: #ffffff;
    --str-chat__message-list-color: var(-text-low-emphasis-color);
    overflow: hidden;
    margin-top: 1.5px;
    width: auto;
    @media screen and (max-width: 1200px) {
        margin-top:0;
      }
}

.str-chat__thread-list {
    background: white;
    border-bottom: var(--border-stroke);
}
.css-11vw377{
    margin-top:81px !important;
    border-radius: none !important;
}