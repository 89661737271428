html,
body {
    margin: 0 !important;
    padding: 0;
    height: 100%;
    font-family: 'Inter' !important;
}

@font-face {
    font-family: 'Inter';
    src: url('./fonts/Inter-Bold.ttf') format('truetype'), url('./fonts/Inter-Light.ttf') format('truetype'),
        url('./fonts/Inter-Medium.ttf') format('truetype'), url('./fonts/Inter-Regular.ttf') format('truetype'),
        url('./fonts/Inter-SemiBold.ttf') format('truetype');
}
*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
#root {
    height: 100%;
}

.app__wrapper {
    display: flex;
    height: 100%;
    width: 100vw;
    max-width: 100vw;
    border-top: 1px solid var(--border-color);
    // box-shadow: rgba(0, 0, 0, 0.33) 0 1px 4px 0;

    @media only screen and (max-width: 930px) {
        .sidebar {
            width: 100%;
            position: absolute;
            left: -100%;
        }
    }

    @media only screen and (max-width: 768px) {
        .str-chat__thread,
        .pinned-messages__container {
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            z-index: 1000;
            width: 100vw;
            max-width: 100%;
            background: white;
        }
    }
}

// .sidebar {
//   width: 312px;
// }

.channel__container {
    height: 100%;
    width: 20%;
    min-width: 0;
    flex: 1;
}
.str-chat__ul {
    li {
        &:first-child {
            display: none;
        }
    }
}

.date__separator {
    text-transform: uppercase;
    // border-width: 1%;
    font-weight: 200;
    font-size: 12px;
    // &:first-child{
    //     display: none;
    // }
}

.str-chat__thread,
.pinned-messages__container {
    width: 30%;
    min-width: 300px;
}

.app__wrapper_box {
    width: 100%;
    // height: 100vh;
    &_top {
        height: 80px;
        width: 100%;
        border-top: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px !important;
        // width: 100;
        // height: 80px;
        &_left {
            width: auto;
            height: 40px;
            display: flex;
            align-items: center;
            &_badge {
                display: flex;
                flex-direction: row;
                align-items: center;
                text-align: center;
                padding: 4px 8px;
                gap: 8px;
                width: auto;
                height: 22px;
                background: rgba(33, 150, 243, 0.08);
                border-radius: 26px;
                font-weight: 500;
                font-size: 12px;

                color: #2196f3;
            }
            &_input {
                width: 183px;
                height: 30px;
                border: none;
                outline: none;
                padding: 0 10px;
            }
        }
        &_right {
            width: 100px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    &_bottom {
        height: calc(100vh - 80px);
        display: flex;
    }
}
@media only screen and (max-width: 768px) {
    .channel__container{
        width: 100%;
    }
}
