em {
    background: cyan;
    font-style: normal;
}

.header {
    display: flex;
    align-items: center;
    min-height: 50px;
    padding: 0.5rem 1rem;
    background-image: linear-gradient(to right, #8e43e7, #00aeff);
    color: #fff;
    margin-bottom: 1rem;
}

.header a {
    color: #fff;
    text-decoration: none;
}

.header-title {
    font-size: 1.2rem;
    font-weight: normal;
}

.header-title::after {
    content: ' ▸ ';
    padding: 0 0.5rem;
}

.header-subtitle {
    font-size: 1.2rem;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
}

.search-panel {
    display: flex;
}

.search-panel__filters {
    flex: 1;
}

.search-panel__results {
    flex: 3;
}

.searchbox {
    margin-bottom: 2rem;
}

.pagination {
    margin: 2rem auto;
    text-align: center;
}
