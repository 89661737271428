@import '../WorkspaceHeader';
@import '../utils';

.team-channel-header__container {
    @include workspace-header-layout;
    // height: 86px !important;
    @media screen and (max-width: 1200px) {
        height: 87px !important;
    }
}

.team-channel-header__name {
    @include ellipsis-text;
}

.team-channel-header__name.user {
    font: var(--font-weight-medium);
    font-size: 14px;
}

.str-chat__message-attachment-card {
    padding: 10px !important;
}
@media screen and (max-width: 1200px) {
    .team-channel-header__container {
        width: 100% !important;
        display: flex;
    }
}
