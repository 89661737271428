@use '../utils';

.channel-preview {
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
    min-height: 60px;
    padding: 0;
    margin: 10px 0;
    // backgsround: red !important;

    // &.selected {
    //     display: flex;
    //     align-items: center;
    //     height: 37px;
    // }
    &__box {
        height: 50px;
        align-items: space-between;
        &_bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 2px;
            &_paragraph {
                font-style: normal;
                font-weight: 300 !important;
                font-size: 12px !important;
            }
        }
    }
}

.channel-preview__box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    &_paragraph {
        font-weight: 500;
        font-size: 10px !important;
        color: var(--text-color-secondary) !important;
    }
    &_paragraph1 {
        font-weight: 500;
        font-size: 10px !important;
        background: var(--primary-color) !important;
        color: var(--static-background-color) !important;
        padding: 4px;
        border-radius: 5px;
    }
}

.channel-preview__box_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.channel-preview__image {
    height: 32px;
    width: 32px;
    margin-right: 5px;
    border-radius: 50%;
    object-fit: cover;
}

.channel-preview__item {
    display: flex;
    align-items: center;
    // max-width: 220px;
    height: 100%;
    width: 100%;
    padding: 0 10px;

    p {
        @include ellipsis-text;
        font-weight: 500;
        font-size: 14px;
        color: var(--text-color) !important;
    }

    &.single .str-chat__avatar {
        margin-right: 12px;
    }

    &.multi .str-chat__avatar {
        margin-right: 0;
    }

    &.multi span:first-child {
        position: relative;
        z-index: 2;
        bottom: 6px;
    }

    &.multi div:nth-child(2) {
        position: relative;
        right: 10px;
        z-index: 1;
        margin-bottom: 0;
    }
}

::placeholder {
    font-weight: normal;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    // font-weight: 400;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    // font-weight: 400;
}
