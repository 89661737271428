@import '../WorkspaceHeader';
@import '../utils';

.admin-panel__form {
    .admin-panel__form-header {
        @include workspace-header-theme;
        width: 100%;
    }

    .admin-panel__form-footer {
        background: var(--static-background-color);
        border-bottom-right-radius: 16px;

        button {
            &:first-child {
                border: 1px solid var(--primary-color);
                color: var(--primary-color);
                background: none;
            }
            border: none;
            background: var(--primary-color);
            // font: var(--font-weight-bold) 18px var(--font-family);
            color: var(--static-background-color);
            font-weight: 500;
            font-size: 14px;
            line-height: 120%;
            border-radius: 8px;
            cursor: pointer;
        }
    }

    .channel-name-input-field {
        h2 {
            font: var(--font-weight-regular) 16px;
        }

        input {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 120%;
            /* identical to box height, or 17px */

            /* Text/Secondary */

            color: var(--text-color-secondary);
            background: var(--panel-primary-background-color);
            border: var(--border-stroke);
            border-radius: 8px;

            &:focus {
                border: 1px solid var(--primary-color);
                outline: none;
            }

            &::placeholder {
                font-weight: var(--font-weight-light);
                color: rgba(0, 0, 0, 0.5);
            }
        }
    }

    .admin-panel__form-validation-error {
        color: var(--error-color);
        font-size: 14px;
        font-style: italic;
    }
}
