@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;700;800;900&display=swap');
:root {
    --primary-color: #ee9a8f;
    --primary-color-alpha: #005fff1a;
    --static-background-color: #fff;
    --color-black: #000000;
    --header-height: 80px;
    --header1-height: 60px;

    --font-weight-thin: 200;
    --font-weight-light: 300;
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-bold: 700;
    --font-weight-extrabold: 800;
    --font-weight-black: 900;
    --font-family: 'Inter', sans-serif;

    --text-color: #393d4e;
    --text-low-emphasis-color: #7a7a7a;
    --text-color-secondary: #677487;
    // --text-color-tertiary: #677487;

    --border-color: #d4dbea;
    --border-stroke: 1px solid var(--border-color);

    --header-title-text: var(--font-weight-bold) 18px/22px var(--font-family);
    --header-subtitle-text: 14px/18px var(--font-family);

    --panel-primary-background-color: #f7f6f8;
    --panel-secondary-background-color: #e9e9ea;

    --error-color: #d0021b;
}

.str-chat {
    --str-chat__font-family: var(--font-family);
    --str-chat__avatar-background-color: var(--primary-color);
}
