@mixin highlighted-button {
    background: rgba(0, 0, 0, 0.1); // changed 2023-01-25
    // border-top-right-radius: 8px; // commented 2023-01-25
    // border-bottom-right-radius: 8px; // commented 2023-01-25
    font-weight: var(--font-weight-bold);
}

.channel-preview {
    background: transparent;
    border: none;
    cursor: pointer;

    &.selected {
        @include highlighted-button;
    }

    &:hover {
        @include highlighted-button;
    }
}

.channel-preview__item {
    font-size: 14px;
    color: white;
}
