.sidebar {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    position: sticky;
    width: auto;
    height: 100vh;
    left: 0px;
    top: 0px;

    /* Grey/50 */

    background: var(--static-background-color);
    /* Grey/300 */

    border-right: 1px solid var(--border-color);
    .sidebar__company-selection-bar {
        width: 72px;
        padding: 14px;

        .sidebar__company-badge {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 44px;
            height: 44px;
        }
    }
    .channel-list-bar {
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        align-items: center;
        // padding: 0 24px;
        width: 274px;
        // width:  100%;
        height: 100%;
        position: relative;
        border-right: 1px solid var(--border-color);

        .channel-list-bar_box {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            height: 80px;
            padding: 0 24px;
            border-width: 0px 1px 1px 0px;
            border-style: solid;
            border-color: var(--border-color);
        }
        .channel-list-bar_box1 {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 85%;
            height: auto;
        }
        .channel-list-bar_box2 {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            height: auto;
            padding: 20px 0;
            border-width: 0px 0 1px 0px;
            border-style: solid;
            border-color: var(--border-color);
        }
        .channel-list-bar_paragraph {
            margin: 15px 0;
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 12px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.12em;
            text-transform: uppercase;
            color: var(--text-color-secondary);
        }
        .channel-list-bar_paragraph1 {
            margin: 10px 0;
            display: flex;
            align-items: center;
            text-align: center;
            font-style: normal;
            // font-weight: 500;
            font-size: 14px;
            color: var(--text-color-secondary);
            p {
                font-size: 14px;
                font-weight: 500;
            }
        }
        .channel-list-bar_image {
            width: 18px;
            height: 18px;
            margin-right: 10px;
        }
        .channel-list-bar_end {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 24px;
            position: absolute;
            width: 100%;
            height: 80px;
            left: 0px;
            bottom: 0px;
            border-width: 1px 1px 0px 0px;
            border-style: solid;
            border-color: var(--border-color);
        }
        .channel-list-bar_endImage {
            width: 32px;
            height: 32px;
            border-radius: 8px;
        }
        .channel-list-bar__header {
            padding-inline: 8px;
            height: auto;
            color: var(--text-color);
            display: flex;
            flex-direction: column;
        }
        .channel-list-bar__logo {
            width: 32px;
            height: 32px;
            border-radius: 8px;
        }
    }
}

.sidebar-side1 {
    width: auto;
    min-width: 250px;
    max-width: 250px;
    // height:calc(100% - 80px);
    // margin-top:80px;
    overflow-y: scroll;
    // scrollbar-width: none;
    padding-right: 0px;
    margin-right: 0px;
    border-right: 1px solid var(--border-color);
    &_Box {
        // height:80px;
        // border-bottom: 1px solid var(--border-color);
        // border-top:1px solid var(--border-color);
        height: var(--header1-height);
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: var(--text-color);
        display: flex;
        align-items: center;
        padding: 15px 25px;
        width: 250px;
        &_Paragraph {
            display: flex;
            align-items: center;
            p {
                color: var(--static-background-color);
                background: var(--primary-color);
                margin-left: 5px;
                padding: 3px;
                border-radius: 8px;
            }
        }
    }
}
.sidebar-side1::-webkit-scrollbar {
    display: none; // Added - to make scrollbar invisible & without margin/pagging
}
@media only screen and (max-width: 768px) {
    .sidebar-side1{
        max-width: 100%;
        border: none;
    }
}
