.str-chat {
    --str-chat__channel-list-background-color: transparent;
}
.str-chat__channel-list-empty {
    svg {
        height: 50px;
        width: 50px;
    }
    p {
        font-size: 15px !important;
    }
}
.str-chat__channel-list-empty-v1 {
    display: none;
}

.team-channel-list {
    .str-chat__avatar-fallback {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), var(--primary-color);
    }

    .team-channel-list__message {
        color: white;
    }

    .team-channel-list__header {
        padding: 20px 25px;
        border-top: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);
        .team-channel-list__header__title {
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 12px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.12em;
            text-transform: uppercase;

            color: #7a7a7a;
        }

        button {
            background: transparent;
            border: none;
            cursor: pointer;
        }
    }
}
