@import '../WorkspaceHeader';
@import '../utils';

.admin-panel__form {
    // height: 100%;
    display: flex;
    flex-direction: column;
    // padding: 32px;
    // gap: 24px;
    isolation: isolate;
    width: 100%;
    // width: 562px;
    overflow-y: scroll;
    // right: 0;
    // top: 0;
    // bottom: 0;
    // width: 562px;
    // max-width: 100%;
    // transform: translateX(145%);
    // transition: transform 0.3s ease-out;
    // z-index: 10;
    // &.is-open {
    //     transform: translateX(0);
    // }

    background: #ffffff;

    .admin-panel__form-header {
        @include workspace-header-layout;
        height: 61px;
        border-style: none;
    }

    .admin-panel__form-footer {
        height: 82px;
        padding: 1rem 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: auto;
        button {
            padding: 10px 20px;
            margin-right: 10px;
        }
    }

    .channel-name-input-field {
        display: flex;
        flex-direction: column;
        height: 117px;
        justify-content: space-around;
        padding-left: 20px; // de-commented by Maxime 2023-01-24

        h2 {
            display: flex;
            align-items: baseline;
            gap: 10px;
            margin: 0;
            padding-block: 30px 0;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #393d4e;
        }

        input {
            padding-left: 16px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            // padding: 0px;
            gap: 6px;
            width: 498px;
            height: 48px;
        }
    }
}

// Added Maxime 2023-03-03 to fix TSM-900
.overflow-y {
    overflow-y: scroll;
}

.css-19kzrtu {
    padding: 0;
}
