.team-message-input__input .message-input__controls-button:first-of-type {
    // border-right: 1px solid rgba(204, 204, 204, 0.5);
}

.team-message-input__wrapper {
    background: white;

    .team-message-input__input {
        .team-message-input__top {
            background: white;
        }

        .team-message-input__bottom {
            background: white;
        }
    }
}

.upload-icon {
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    &:hover {
        background-color: var(--primary);
    }
}

.upload-icon__img {
    width: 28px;
    height: 28px;
    padding-top: 3px;
    padding-right: 8px;
    fill: currentColor;
    transition: fill 0.2s ease-in-out;
}

.upload-icon:hover .upload-icon__img {
    fill: var(--primary-color);
}

.team-message_replies {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    isolation: isolate;
    margin: 10px 0;
    width: 304px;
    height: 39px;
    &_dots {
        position: absolute;
        width: 10px;
        height: 85px;
        left: -19px;
        top: -56px;
    }
    &_box {
        // background: red;
        // height:50px;
        height: auto;
        width: auto;
        // display: flex;
        // align-items: flex-start;
        &_top {
            height: auto;
            display: flex;
            align-items: center;
            width: 200px;
            justify-content: space-between;
            margin-bottom: 10px;
            time {
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                letter-spacing: 0.12em;
                text-transform: uppercase;

                /* Text/Tertiary */

                color: #7a7a7a;
            }
            &_dot {
                height: 5px;
                width: 5px;
                background-color: #7a7a7a;
                border-radius: 50%;
                margin-top: 5px;
            }
            &_author {
                font-weight: 500;
                font-size: 14px;
                line-height: 120%;
                color: var(--text-color);
            }
            &_authorinfo {
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                letter-spacing: 0.12em;
                text-transform: uppercase;

                /* Text/Tertiary */

                color: #7a7a7a;
            }
        }
        &_bottom {
            // margin-left: 32px;
            font-style: normal;
            margin-top: 10px;
            font-weight: 300;
            font-size: 12px;
            line-height: 120%;
            color: var(--text-color-secondary);
        }
    }
}
