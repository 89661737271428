@mixin workspace-header-theme {
    border-bottom: var(--border-stroke);
    background: var(--static-background-color);

    .workspace-header__title {
        // font: var(--header-title-text);
        color: var(--text-color);
        font-weight: 500;
        font-size: 14px;
        display: flex;
        align-items: center;
        &_image {
            height: 32px;
            width: 32px;
            border-radius: 50%;
            margin-right: 5px;
        }
    }
    .workspace-header__right {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 0px;
        gap: 8px;
        width: 344px;
        height: 48px;
        border-radius: 8px;
        &_input {
            width: 90px;
            border: none;
            outline: none;
            // background: none;
        }
        &_icons {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &_images {
            height: 40px;
            width: auto;
            display: flex;
            align-items: center;
            &_image {
                height: 35px;
                width: 35px;
                border-radius: 50%;
                object-fit: cover;
                background: var(--static-background-color);
                display: flex;
                align-items: center;
                justify-content: center;
                &:not(:first-child) {
                    margin-left: -7px;
                }
            }
            &_image1 {
                height: 32px;
                width: 32px;
                border-radius: 50%;
            }
        }
    }

    .workspace-header__subtitle {
        // font: var(--header-subtitle-text);
        color: var(--text-low-emphasis-color);
    }

    button {
        cursor: pointer;
    }
}
